import styled from 'styled-components'

export const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: bold;
  cursor: pointer;
`;

export const Title = styled.p`
  margin-left: 24px;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;

  @media(max-width: 800px) {
    font-size: 20px;
  }
`;

export const TitleHeader = styled.div`
  
`;

export const InfoBox = styled.div`
  background: ${props => props.color};
  padding: 16px;
  margin: 24px;
  color: #FFF;
  border-radius: 10px;
`;

export const InfoHeader = styled.div`
  justify-content: space-between;
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #98A2B3; 

  > p {
    @media(max-width: 800px) {
      flex: 1;
    }
  }
`;

export const InfoTitle = styled.p`
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  margin: 0;

  @media(max-width: 800px) {
    font-size: 18px;
  }
`;

export const InfoPrice = styled.p`
  margin: 0;
  font-size: 16px;
  font-style: normal;

  b {
    font-size: 30px;
  }

  @media(max-width: 800px) {
    font-size: 10px;

    b {
      font-size: 14px;
      display: block;
    }
  }
`;

export const InfoDescription = styled.div`
  padding-top: 14px;
  font-size: 12px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #EAECF0;
`;

export const FormBox = styled.div`
  padding: 14px 24px;

  label {
    font-size: 14px;

    em {
      font-size: 12px;
    }
  }
`;

export const AddressBox = styled.div`
  background: #F9FAFB;
  border-radius: 5px;
  padding: 10px;

  p {
    margin: 0;
    font-weight: bold;
  }

  @media(max-width: 800px) {
    margin-top: 12px;
  }
`;

export const LoadingWrapper = styled.div`
  background: #FFF;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;

export const LoadingBox = styled.div`
  background: #F5f5f5;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 10px 0 0;
  }
`;

export const SuccessWrapper = styled.div`
  background: #FFF;
  text-align: center;
`;

export const SuccessBox = styled.div`
  margin: 30px auto 60px;
  max-width: 1100px;

  h2 {
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    margin-bottom: 20px;
  }

  p {
    margin: 0;
    font-size: 30px;
    line-height: 38px;
    color: #475467;
    margin-bottom: 24px;
  }

  .videoFrame {
    max-width: 900px !important;
    aspect-ratio: 16 / 9;
    margin-bottom: 32px;
  }

  @media(max-width: 800px) {
    margin: 30px 10px;

    h2 {
      font-size: 30px;
      line-height: 38px;
    }
  
    p {
      font-size: 16px;
      line-height: 24px;
    }
  
    .videoFrame {
      // width: auto !important;
      height: auto;
      width: 100%;
      
    }
  }
`;

export const IconInput = styled.div`
  position: relative;

  i {
    position: absolute;
    left: 10px;
    top: 6px;
    z-index: 9999;
  }

  .form-control {
    padding-left: 35px
  }
`;
