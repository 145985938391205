export const createUser = async (payload) => {
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  };

  try {
    //const resp = await fetch('http://localhost:3030/create-new-payment', options);
    const resp = await fetch('https://apiprojetobem.simplesnologia.biz/create-new-payment', options);
  
    if (resp.status && resp.status === 200) {
      return {success: true}
    }

    return {success: false}
  } catch(e) {
    return {success: false}
  }
  
}

export const createPayment = async (CEP) => {
  console.log(CEP)
}

