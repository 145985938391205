import React, { useState } from 'react';
import {
  Wrapper, Header, PriceTierCards, PriceTierCard, Badge,
  PriceTierHead, PriceTierPriceBlock, PriceTierPrice,
  BuyButtom, Benefits, BenefitsTitle, BenefitsWelcome,
  BenefitsList, FAQBox, FAQAccordion, AccordionItem,
  FAQTitle, FAQText, BoxSpacer, FaqDivider,
  CustomWrapper, CustomTitle, CustomButton,
  Averages, AveragesList, AveragesListItem,
  CustomTierTitle, CustomTierSubTitle,
} from '../Atoms';

const averagesItems = [
  {
    title: 'Resgate',
    description: 'Estudos, pesquisas e práticas das manifestações populares brasileiras.',
  },
  {
    title: 'Fomento',
    description: 'Promovendo eventos culturais, palestras, oficinas, workshops com mestres dos brincantes populares, visitas a exposições, espaços e instituições culturais.',
  },
  {
    title: 'Contribuição',
    description: 'Composições, criações e montagem de um espetáculo anual próprio e temático, baseado nas vivências diárias.',
  },
  {
    title: 'Aulas diárias',
    description: 'Aulas diárias de iniciação musical, canto coral, percussão (ritmos brasileiros), cordas, madeiras e metais.',
  },
  {
    title: 'Vivências semanais',
    description: 'Vivências semanais de apoio cultural de artes, criação de poesias coletivas e individuais, teatro e danças brasileiras.',
  },
  {
    title: 'Identidade cultural',
    description: 'Resgate e valorização da identidade cultural do nosso país.',
  },
  {
    title: 'Valorização',
    description: 'Fortalecimento da autoestima e valorização de si e do outro.',
  },
  {
    title: 'Cultura',
    description: 'Acesso as mais distintas formas culturais e suas expressões, combatendo o preconceito e a discriminação.',
  },
  {
    title: 'Empatia',
    description: 'Abertura ao diálogo, empatia e respeito às diferenças de valores culturais, essenciais para a construção de uma sociedade mais justa, solidária e inclusiva.',
  }
]

function Landing({ onOpenCheckout }) {
  const [accordionItems, setAccordionItems] = useState({
    AC1: false,
    AC2: false,
    AC3: false,
  });

  const toogleHeight = (item) => {
    const newACs = { ...accordionItems };

    if (newACs[item]) {
      newACs[item] = false;
    } else {
      newACs[item] = true;
    }

    setAccordionItems(newACs);
  }
  return (<>
    <Wrapper>
      <Header className="App-header">
        <span>Projeto Brasil Essência Musical</span>
        <h1>O BEM maior é cantar os meus Brasis.</h1>
        <p>Nossa missão é oferecer acesso à educação musical para crianças, adolescentes e adultos em vulnerabilidade social, com base em três pilares: resgate, fomento e contribuição para a cultura popular brasileira.</p>
        <iframe className="videoFrame" src="https://www.youtube.com/embed/9yexKcYX1CI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Header>
      <Averages>
        <span>Projeto Brasil Essência Musical</span>
        <h4>Os benefícios do BEM.</h4>
        <AveragesList>
          {averagesItems.map((item) => (
            <AveragesListItem>
              <h5>{item.title}</h5>
              <p>{item.description}</p>
            </AveragesListItem>
          ))}
        </AveragesList>
      </Averages>
      <CustomTierTitle>Seja nosso associado e nos ajude a fazer o BEM.</CustomTierTitle>
      <CustomTierSubTitle>Associe-se e faça parte contribuindo para o nosso projeto chegar a cada vez mais pessoas.</CustomTierSubTitle>
      <PriceTierCards>
        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead inputColor="#FC0">
              <p><b>BEM-gratidão</b></p>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice inputColor="#FC0">R$ 19,90</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Cultive sonhos, inspire notas.</p>
            <p>&nbsp;</p>
            <BuyButtom inputColor="#FC0" onClick={() => onOpenCheckout('bem-vindo')}>Quero ser Bem Gratidão</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check1">
              <li><b>1 ingresso</b> anual para o Espetáculo do BEM no Teatro.</li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>

        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead inputColor="#008FFE">
              <p><b>BEM +</b></p>
              <Badge>+Popular</Badge>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice inputColor="#008FFE">R$ 39,90</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Bem vindo ao nosso universo cultural.</p>
            <p>&nbsp;</p>
            <BuyButtom inputColor="#008FFE" onClick={() => onOpenCheckout('bem+')}>Quero ser Bem Mais</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check2">
              <li><b>1 Ingresso</b> anual para o Espetáculo do BEM no Teatro.</li>
              <li><b>1 Abadá </b> de entrada ao nosso evento de Carnaval.</li>
              <li><b>1 Ingresso </b> da nossa tradicional festa junina.</li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>

        <PriceTierCard>
          <BoxSpacer>
            <PriceTierHead inputColor="#3C6">
              <p><b>BEM-iluminado</b></p>
            </PriceTierHead>
            <PriceTierPriceBlock>
              <PriceTierPrice inputColor="#3C6">R$ 99,90</PriceTierPrice>
              <p>por mês</p>
            </PriceTierPriceBlock>
            <p>Amplie a melodia, multiplique o impacto.</p>
            <p>&nbsp;</p>
            <BuyButtom inputColor="#3C6" onClick={() => onOpenCheckout('bem-iluminado')}>Quero ser Bem Iluminado</BuyButtom>
          </BoxSpacer>
          <Benefits>
            <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
            <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
            <BenefitsList checkType="check3">
              <li><b>1 Ingresso</b> anual para o Espetáculo do BEM no Teatro.</li>
              <li><b>1 Abadá </b> de entrada ao nosso evento de Carnaval.</li>
              <li><b>1 Ingresso </b> da nossa tradicional festa junina.</li>
              <li><b>1 Ingresso </b> para apresentação da Orquestra BEM.</li>
            </BenefitsList>
          </Benefits>
        </PriceTierCard>
      </PriceTierCards>
    </Wrapper>
    <CustomWrapper>
      <div>
        <span>Projeto Brasil Essência Musical</span>
        <CustomTitle>Seja um associado <b>BEM-especial empresa</b>: Sua Contribuição, Sua Melodia!</CustomTitle>
        <p>Convide o poder da sua paixão pela música e a generosidade do seu coração para criar uma melodia única de impacto. Com o Plano <b>BEM-especial empresa</b>, você tem a liberdade de moldar a harmonia do nosso projeto, contribuindo com valores a partir de R$ 200,00 por mês. Cada nota que você adiciona à nossa sinfonia de transformação ressoará na vida de crianças, adolescentes e adultos em vulnerabilidade social, resgatando tradições, fomentando o conhecimento musical e contribuindo para a riqueza da cultura brasileira. Junte-se a nós e deixe sua marca pessoal em uma melodia de mudança e esperança. Seja um Associado BEM-especial e faça parte da trilha sonora da transformação.</p>
        <CustomButton onClick={() => onOpenCheckout('Custom')}>
          Quero ser BEM-especial empresa
        </CustomButton>
        <Benefits>
          <p>&nbsp;</p>
          <BenefitsTitle>BENEFÍCIOS</BenefitsTitle>
          <BenefitsWelcome>Esse é o nosso kit de boas-vindas...</BenefitsWelcome>
          <BenefitsList checkType="check3">
            <li><b>2 Ingresso</b> anual para o Espetáculo do BEM no Teatro.</li>
            <li><b>2 Abadá </b> de entrada ao nosso evento de Carnaval.</li>
            <li><b>2 Ingresso </b> da nossa tradicional festa junina.</li>
            <li><b>2 Ingresso </b> para apresentação da Orquestra BEM.</li>
            <li><b>1 workshop</b> para oficina prática de percussão. Formação com duração de 4 horas com direito a diploma (previamente agendado e os recursos necessários por conta da empresa).</li>
            <li><b>1 Uma Apresentação</b> da Orquestra BEM em evento da empresa (previamente agendado e os recursos necessários por conta da empresa).</li>
          </BenefitsList>
        </Benefits>
      </div>
    </CustomWrapper>
    <Wrapper>
      <FAQBox>
        <h2>Perguntas Frequentes</h2>
        <FAQAccordion>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC1')}>
              A minha associação ajudará em quê?
              <i className={`bi bi-chevron-${accordionItems.AC1 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC1 ? '' : 'd-none'}>
              Sua contribuição nos ajudará a manter o nosso projeto e levar educação musical  para crianças, adolescentes e adultos em vulnerabilidade social.
            </FAQText>
          </AccordionItem>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC2')}>
              Minha associação irá consumir o limite do meu cartão de crédito?
              <i className={`bi bi-chevron-${accordionItems.AC2 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC2 ? '' : 'd-none'}>
              Pode ficar tranquilo: a sua contribuição não irá consumir o limite do seu cartão de crédito. O débito de sua contribuição será feita mensalmente sem comprometer seu limite.
            </FAQText>
          </AccordionItem>
          <AccordionItem>
            <FAQTitle onClick={() => toogleHeight('AC3')}>
              Eu posso cancelar minha associação a qualquer momento?
              <i className={`bi bi-chevron-${accordionItems.AC3 ? 'down' : 'up'}`}></i>
            </FAQTitle>
            <FAQText className={accordionItems.AC3 ? '' : 'd-none'}>
              Pode sim. Para isso é necessário entrar em contato com o e-mail financeiro@projetobemoficial.org.br.
            </FAQText>
          </AccordionItem>
        </FAQAccordion>
      </FAQBox>
    </Wrapper>
  </>);
}

export default Landing;
