import styled from 'styled-components'
import check from './Assets/check.png';
import check1 from './Assets/check-yellow.png';
import check2 from './Assets/check-blue.png';
import check3 from './Assets/check-green.png';

const checks = {
  check1,
  check2,
  check3
}

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding-top: 20px;
`;

export const Header = styled.div`
  padding: 24px 10% 32px;
  overflow: hidden;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 12px;
    color: #364152;
  }
  h1 {
    font-size: 60px;
    font-weight: 700;
  }
  p {
    padding: 20px 5%;
    font-size: 30px;
    color: #475467;
  }
  iframe {
    margin: 0 0 32px 0;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  }
  @media(max-width: 800px) {
    padding: 0 3%;
    h1 {
      font-size: 36px;
    }
    p {
      padding: 20px 0;
      font-size: 18px;
    }
    iframe {
      // height: 205px;
      height: auto;
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
`;

export const PriceTierCards = styled.div`
  display: flex;
  gap: 32px;
  align-items: flex-start;
  padding-bottom: 40px;

  @media(max-width: 800px) {
    display: block;
    padding: 0 12px;
    padding-bottom: 12px;
  }
`;

export const PriceTierCard = styled.div`
  border: 1px solid #EAECF0;
  border-radius: 5px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  flex: 1;

  @media(max-width: 800px) {
    margin-bottom: 20px;
  }
`;

export const BoxSpacer = styled.div`
  border-bottom: 1px solid #EAECF0;
  padding: 32px;
  text-align: left;
`;

export const PriceTierHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;

    b {
      font-size: 30px;
      color: ${props => props.inputColor || "#000"};
    }
  }

  @media(max-width: 800px) {
    p {
      font-size: 16px;
  
      b {
        font-size: 24px;
      }
  }
`;

export const Badge = styled.span`
  background: rgba(0, 143, 254, 0.20);
  border: 1px solid rgba(0, 143, 254, 0.10);;
  border-radius: 20px;
  color: #008ffe;
  font-size: 14px;
  margin: 0;
  padding: 2px 6px;
`;

export const PriceTierPriceBlock = styled.div`
  display: flex;
  align-items: baseline;
`;

export const PriceTierPrice = styled.div`
  font-size: 60px;
  font-weight: 600;
  padding-right: 10px;
  color: ${props => props.inputColor || "#993233"};

  @media(max-width: 800px) {
    font-size: 48px;
  }
`;

export const BuyButtom = styled.div`
  margin-top: 26px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
  border-radius: 10px;
  padding: 20px 0;
  color: #FFF;
  background: ${props => props.inputColor || "#993233"};

  &:hover {
    opacity: 0.8;
  }
`;

export const Benefits = styled.div`
  padding: 32px;
  text-align: left;
  min-height: 360px;
`;

export const BenefitsTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

export const BenefitsWelcome = styled.div`
`;

export const BenefitsList = styled.ul`
  list-style:none;
  padding: 0;

  li {
    background:url(${props => checks[props.checkType]}) no-repeat 0 0;
    padding-left: 34px;
    margin-top: 16px;
    font-size: 16px;
  }
`;

export const FaqDivider = styled.div`
  border-bottom: 1px solid #EAECF0;
  position: relative;
  margin-top: 60px;
  margin-bottom: 30px;

  span {
    position: absolute;
    top: -14px;
    left: 47%;
    background: #fff;
    padding: 0 10px;

    @media(max-width: 800px) {
      left: 36%;
    }
  }
`;

export const FAQBox = styled.div`
  padding-bottom: 24px;
`;

export const FAQAccordion = styled.div`
  text-align: left;
  max-width: 770px;
  margin: 0 auto;
  
  @media(max-width: 800px) {
    padding: 0 10px;
  }
`;

export const AccordionItem = styled.div`
  border-top: 1px solid #EAECF0;
  padding-top: 24px;
  margin-top: 32px;

 
`;

export const FAQTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const FAQText = styled.div`
  padding-top: 18px;
  font-size: 16px;
`;

export const Footer = styled.div`
  background: #EAECF0;
  padding: 10px 0;
  margin-top: 30px;

  img {
    width: 180px;
    height: 25px;
  }
`;

export const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: #BF4F74;
`;

export const CustomWrapper = styled.div`
  background: #079455;
  color: #FFF;
  padding: 32px 0 48px;

  @media(max-width: 800px) {
    padding: 32px 24px;
  }

  > div {
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    p {
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 38px;

      @media(max-width: 800px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export const CustomTitle = styled.h3`
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
  padding-bottom: 24px;
  margin: 0;

  @media(max-width: 800px) {
    font-size: 30px;
    line-height: 38px;
    font-weight: 500;
    padding: 10px 24px 24px;
  }

  b {
    color: #FF0;
  }
`;

export const CustomButton = styled.div`
  margin-top: 26px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 30px;
  border-radius: 10px;
  padding: 20px 40px;
  color: #FF0;
  background: #000;

  i {
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.8;
  }

  @media(max-width: 800px) {
    font-size: 20px;
    padding: 10px 16px;
  }
`;

export const Averages = styled.div`
  text-align: left;
  margin: 0 auto;
  max-width: 1440px;
  padding: 20px 0 40px;
  
  span {
    color: #364152;
    font-size: 16px;
    font-weight: 600;
  }

  h4 {
    color: #101828;
    font-size: 36px;
    font-weight: 600;
    padding: 16px 0;
  }

  @media(max-width: 600px) {
    margin: 0 12px;

    span {
      font-size: 14px;
    }

    h4 {
      font-size: 24px;
      padding: 8px 0 14px;
    }
  }
`;

export const AveragesList = styled.div`
  display:flex;
  flex-wrap:wrap;
  gap: 24px;
  align-items: flex-start;
  margin-bottom: 40px;

  @media(max-width: 600px) {
    margin-bottom: 24px;
  }

  > div {
    width: calc((100% - 48px) /3);
    padding: 24px;

    @media(max-width: 1000px) {
      width: calc((100% - 24px) /2);
    }

    @media(max-width: 600px) {
      width: 100%;
    }
  }
`;

export const AveragesListItem = styled.div`
  background: #F9FAFB;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  h5 {
    font-size: 24px;
    font-weight: 700;

    @media(max-width: 600px) {
      font-size: 18px;
    }
  }
  
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px
  }
`;

export const CustomTierTitle = styled.h3`
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  margin-bottom: 8px;

  @media(max-width: 800px) {
    margin: 0 10px;
    font-size: 30px;
    line-height: 38px;
  }
`;

export const CustomTierSubTitle = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-bottom: 40px;
  color: #475467;

  @media(max-width: 800px) {
    margin: 16px 10px;
    font-size: 16px;
    line-height: 24px;
  }
`;
