import React, { useState, useEffect, useRef } from 'react';
import {
  CloseButton, Title, InfoBox, InfoHeader,
  InfoTitle, InfoPrice, InfoDescription,
  Divider, AddressBox, FormBox, LoadingBox,
  LoadingWrapper, SuccessWrapper, SuccessBox,
  IconInput
} from './CheckoutAtoms';
import InputMask from 'react-input-mask';
import { ColorRing } from 'react-loader-spinner'
import Moment from 'moment';
import ConsultaCep from '../Services/BrasilAPI';
import { createUser } from '../Services/AsaasAPI';
import CreditCard from '../Components/CreditCard';
import CurrencyInput from '../Components/CurrencyInput';

const plans = [{
  name: 'bem-vindo',
  title: 'BEM-gratidão',
  color: '#FC0',
  price: 19.9,
  displayPrice: '19,90',
  description: '1 Ingresso anual para o Espetáculo do BEM no Teatro.',
  asaasDescription: 'Plano BEM-gratidão'
}, {
  name: 'bem+',
  title: 'BEM+',
  color: '#008FFE',
  price: 39.9,
  displayPrice: '39,90',
  description: '1 Ingresso anual para o Espetáculo do BEM no Teatro, 1 Abadá de entrada ao nosso evento de Carnaval, 1 Ingresso da nossa tradicional festa junina.',
  asaasDescription: 'Plano BEM+'
}, {
  name: 'bem-iluminado',
  title: 'BEM-iluminado',
  color: '#3C6',
  price: 99.9,
  displayPrice: '99,90',
  description: '1 Ingresso anual para o Espetáculo do BEM no Teatro,1 Abadá de entrada ao nosso evento de Carnaval, 1 Ingresso da nossa tradicional festa junina, 1 Ingresso para apresentação da Orquestra BEM.',
  asaasDescription: 'Plano BEM-iluminado'
}, {
  name: 'Custom',
  title: 'BEM-especial empresa',
  color: '#079455',
  price: 0,
  displayPrice: '0',
  description: '2 Ingresso anual para o Espetáculo do BEM no Teatro, 2 Abadá de entrada ao nosso evento de Carnaval, 2 Ingresso da nossa tradicional festa junina,2 Ingresso para apresentação da Orquestra BEM, 1 workshop para oficina prática de percussão. Formação com duração de 4 horas com direito a diploma (previamente agendado e os recursos necessários por conta da empresa), Uma Apresentação da Orquestra BEM em evento da empresa (previamente agendado e os recursos necessários por conta da empresa).',
  asaasDescription: 'Plano BEM-especial empresa'
}];



function Checkout({ plan, onCloseCheckout }) {
  const [address, setAddress] = useState({});
  const [holderAddress, setHolderAddress] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [cardOwnerHolder, setCardOwnerHolder] = useState();
  const [ip, setIP] = useState("");
  const [isloading, setIsLoading] = useState();
  const [showError, setShowError] = useState();
  const [showPriceError, setShowPriceError] = useState();
  const [showSuccess, setShowSuccess] = useState();
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [customValue, setCustomValue] = useState(200.0);

  const [userForm, setUserForm] = useState({});
  const [holderForm, setHolderForm] = useState({});

  const validateForm = () => {
    if (
      userForm.name &&
      userForm.cpf &&
      userForm.email &&
      userForm.mobilePhone &&
      userForm.addressNumber &&
      address.cep &&
      (userForm.shirt || plan === 'bem-vindo') &&
      userForm.creditCard &&
      userForm.creditCard.number &&
      userForm.cardExpires &&
      userForm.cvc &&
      userForm.cardName &&
      (!cardOwnerHolder || cardOwnerHolder &&
        holderForm.name &&
        holderForm.cpf &&
        holderForm.mobilePhone &&
        holderForm.email &&
        holderAddress.cep &&
        holderForm.addressNumber
      )
    ) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  };

  const updateUserForm = (field, value) => {
    let newForm = {
      ...userForm,
    }

    newForm[field] = value;

    setUserForm(newForm);
    validateForm();
  };

  const updateHolderForm = (field, value) => {
    let newForm = {
      ...holderForm,
    }

    newForm[field] = value;

    setHolderForm(newForm);
    validateForm();
  };

  const getIPData = async () => {
    const response = await fetch("https://api.ipify.org/?format=json");
    const res = await response.json();

    if (plan !== 'Custom') {
      setInput1Focus();
    }

    setIP(res.ip);
  };

  useEffect(() => {
    getIPData();
    if (plan !== 'Custom') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (plan) {
      const x = plans.find(i => i.name === plan);

      setSelectedPlan(x)
    }
  }, [plan]);

  useEffect(() => {
    validateForm();
  }, [cardOwnerHolder]);

  const UseFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
  }

  const [input1Ref, setInput1Focus] = UseFocus()

  return (
    <div>
      {showError && (
        <div className="alert alert-danger" style={{ margin: '0 10%', position: 'fixed', zIndex: 9999, top: '10px', right: '10px' }} role="alert">
          Ocorreu um erro ao processar sua assinatura, por favor tente novamente mais tarde.
        </div>
      )}
      {showPriceError && (
        <div className="alert alert-danger" style={{ margin: '0 10%', position: 'fixed', zIndex: 9999, top: '10px', right: '10px' }} role="alert">
          A contribuição do Plano BEM-especial empresa não pode ser inferior a R$ 200,00.
        </div>
      )}
      <div className={`CheckoutBox ${(isloading || showSuccess) && 'd-none'}`}>
        <CloseButton onClick={() => onCloseCheckout()}>X</CloseButton>
        <Title>Informações da sua contribuição</Title>
        <InfoBox color={selectedPlan.color}>
          <InfoHeader>
            <InfoTitle>{selectedPlan.title}</InfoTitle>
            {plan === 'Custom' ? (
              <InfoPrice>
                <b>R$ <CurrencyInput pattern="[0-9]*" style={{ display: 'inline-block', width: '100px' }} selectAllOnFocus autoFocus value={customValue} className="form-control" thousandSeparator="." decimalSeparator="," onChange={(e) => {
                  setCustomValue(e);
                  setShowPriceError();
                }}
                />
                </b> por mês
              </InfoPrice>
            ) : (
              <InfoPrice><b>R$ {selectedPlan.displayPrice}</b> por mês</InfoPrice>
            )}
          </InfoHeader>
          <InfoDescription>
            {selectedPlan ? selectedPlan.description : ''}
          </InfoDescription>
        </InfoBox>
        <Divider></Divider>
        <FormBox>
          <h3>Suas informações</h3>
          <form style={{ margin: '16px 0' }}>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputPassword1">CPF</label>
                <InputMask className="form-control" placeholder="000.000.000-00" mask="999.999.999-99" onChange={(e) => {
                  updateUserForm('cpf', e.target.value)
                }} maskChar="" >
                  {(inputProps) => <input {...inputProps} pattern="[0-9]*" ref={input1Ref} />}
                </InputMask>
              </div>
              <div className="form-group col-md-8">
                <label for="namee">Nome completo</label>
                <input type="text" placeholder="Nome completo" className="form-control" id="namee" onChange={(e) => { updateUserForm('name', e.target.value) }} />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputCel">Celular / Whatsapp</label>
                <IconInput>
                  <i className="bi bi-telephone"></i>
                  <InputMask className="form-control" pattern="[0-9]*" placeholder="11 99999-9999" mask="99 99999-9999" onChange={(e) => { updateUserForm('mobilePhone', e.target.value) }} maskChar="" />
                </IconInput>
              </div>
              <div className="form-group col-md-8">
                <label for="exampleInputEmail1">Email</label>
                <IconInput>
                  <i className="bi bi-envelope"></i>
                  <input type="email" placeholder="eu@email.com" className="form-control" id="exampleInputEmail1" onChange={(e) => { updateUserForm('email', e.target.value) }} />
                </IconInput>
              </div>
            </div>
          </form>
          <Divider style={{ marginBottom: '16px' }}></Divider>
          <h3>Seu kit de boas-vindas</h3>
          <form style={{ padding: '16px 0' }}>
            <div className="row">
              <div className="form-group col-md-4">
                <label for="exampleInputPassword1">CEP</label>
                <IconInput>
                  <i className="bi bi-geo-fill"></i>
                  <InputMask
                    className="form-control"
                    mask="99999-999"
                    placeholder="00000-000"
                    onChange={async (e) => {
                      if (e.target.value.length === 9) {
                        const addressInfo = await ConsultaCep(e.target.value);
                        setAddress(addressInfo);
                      }
                    }}
                    pattern="[0-9]*"
                    maskChar=""
                  />
                </IconInput>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="form-group col-md-4">
                  <label for="exampleInputCel">Número</label>
                  <input pattern="[0-9]*" type="tel" className="form-control" placeholder="000" onChange={(e) => { updateUserForm('addressNumber', e.target.value) }} />
                </div>
                <div className="form-group col-md-8">
                  <label for="comp">Complemento</label>
                  <input type="text" placeholder="Apto 00000 / Casa 00000" className="form-control" id="comp" onChange={(e) => { updateUserForm('complement', e.target.value) }} />
                </div>
              </div>
            </div>
            {(plan !== 'bem-vindo') &&
              <><div className="form-group">
                <label>Qual o tamanho do abadá que você gostaria?</label>
              </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio1" value="P" />
                  <label className="form-check-label" for="inlineRadio1">P</label>
                </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio2" value="M" />
                  <label className="form-check-label" for="inlineRadio2">M</label>
                </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio3" value="G" />
                  <label className="form-check-label" for="inlineRadio3">G</label>
                </div><div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" onClick={(e) => { updateUserForm('shirt', e.target.value); }} name="camisetas" id="inlineRadio4" value="GG" />
                  <label className="form-check-label" for="inlineRadio4">GG</label>
                </div></>
            }
          </form>
          <Divider style={{ marginBottom: '16px' }}></Divider>
          <h3>Seu pagamento</h3>
          <form style={{ padding: '16px 0' }}>
            <div className="row">
              <div className="form-group col-md-4">
                <label>Número do cartão de crédito</label>
                <CreditCard
                  onUpdate={(e) => {
                    updateUserForm('creditCard', e),
                      validateForm();
                  }}
                />
              </div>
              <div className="form-group col-md-4">
                <label for="card_name">Nome do titular <em>(como está gravado no cartão)</em></label>
                <input type="text" placeholder="Nome impresso no cartão" className="form-control" id="card_name" onChange={(e) => { updateUserForm('cardName', e.target.value) }} />
              </div>
              <div className="form-group col-md-2 col-6">
                <label for="cardExpires">Validade</label>
                <InputMask pattern="[0-9]*" className="form-control" placeholder="MM / AA" mask="99 / 99" onChange={(e) => { updateUserForm('cardExpires', e.target.value) }} maskChar="" />
              </div>
              <div className="form-group col-md-2 col-6">
                <label for="cvc">CVC</label>
                <InputMask pattern="[0-9]*" className="form-control" placeholder="***" mask="999" onChange={(e) => { updateUserForm('cvc', e.target.value) }} maskChar="*" />
              </div>
            </div>
          </form>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => {
              setCardOwnerHolder(!cardOwnerHolder);
            }} />
            <label className="form-check-label" for="flexCheckDefault">
              O cartão de crédito é de outro titular que não sou eu
            </label>
          </div>
          {cardOwnerHolder &&
            <div style={{ paddingTop: '20px' }}>
              <h3>Informações do titular</h3>
              <form style={{ margin: '16px 0' }}>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="hexampleInputPassword1">CPF</label>
                    <InputMask className="form-control" placeholder="000.000.000-00" mask="999.999.999-99" onChange={(e) => {
                      updateHolderForm('cpf', e.target.value)
                    }} maskChar="" />
                  </div>
                  <div className="form-group col-md-8">
                    <label for="hnamee">Nome completo</label>
                    <input type="text" placeholder="Nome completo" className="form-control" id="hnamee" onChange={(e) => { updateHolderForm('name', e.target.value) }} />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label for="hexampleInputCel">Celular / Whatsapp</label>
                    <IconInput>
                      <i className="bi bi-telephone"></i>
                      <InputMask className="form-control" placeholder="11 99999-9999" mask="99 99999-9999" onChange={(e) => { updateHolderForm('mobilePhone', e.target.value) }} maskChar="" />
                    </IconInput>
                  </div>
                  <div className="form-group col-md-8">
                    <label for="hexampleInputEmail1">Email</label>
                    <IconInput>
                      <i className="bi bi-envelope"></i>
                      <input type="email" placeholder="eu@email.com" className="form-control" id="hexampleInputEmail1" onChange={(e) => { updateHolderForm('email', e.target.value) }} />
                    </IconInput>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputPassword1">CEP</label>
                      <IconInput>
                        <i className="bi bi-geo-fill"></i>
                        <InputMask className="form-control" mask="99999-999" placeholder="00000-000" onChange={async (e) => {
                          if (e.target.value.length === 9) {
                            const addressInfo = await ConsultaCep(e.target.value);

                            setHolderAddress(addressInfo);
                          }

                        }} maskChar="" />
                      </IconInput>
                    </div>
                    <div className="form-group col-md-8">
                      <AddressBox>
                        <p>{holderAddress.street}</p>
                        <p>{holderAddress.neighborhood} - {holderAddress.city} - {holderAddress.state}</p>
                      </AddressBox>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label for="exampleInputCel">Número</label>
                      <input type="tel" className="form-control" placeholder="000" onChange={(e) => { updateHolderForm('addressNumber', e.target.value) }} />
                    </div>
                    <div className="form-group col-md-8">
                      <label for="comp">Complemento</label>
                      <input type="text" placeholder="Apto 00000 / Casa 00000" className="form-control" id="comp" onChange={(e) => { updateHolderForm('complement', e.target.value) }} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
        </FormBox>
        <Divider></Divider>
        <div style={{ padding: '16px' }} className="d-grid gap-2">
          <button type="submit" disabled={disableSubmit} className="btn btn-success" onClick={async () => {
            let price = selectedPlan.price;

            if (plan === 'Custom') {
              if (customValue === 0 || customValue.length < 6) {
                setShowPriceError(true);
                // setIsLoading();
                return;
              }
              let thousands_seps = '.';
              let decimal_sep = ',';

              let sanitizeValue = customValue.replace(thousands_seps, '').replaceAll(decimal_sep, '.').replace(/[^0-9.-]+/, '');

              price = parseFloat(sanitizeValue);

              if (price < 200.0) {
                setShowPriceError(true);
                // setIsLoading();
                return;
              }
            }

            const expires = userForm.cardExpires.split(' / ')

            const creditCardHolderInfo = cardOwnerHolder ? {
              name: holderForm.name,
              email: holderForm.email,
              cpfCnpj: holderForm.cpf,
              postalCode: holderAddress.cep,
              addressNumber: holderForm.addressNumber,
              addressComplement: holderForm.complement,
              phone: holderForm.mobilePhone
            } : {
              name: userForm.name,
              email: userForm.email,
              cpfCnpj: userForm.cpf,
              postalCode: address.cep,
              addressNumber: userForm.addressNumber,
              addressComplement: userForm.complement,
              phone: userForm.mobilePhone
            }

            setIsLoading(true)
            const resp = await createUser({
              user: {
                name: userForm.name,
                cpfCnpj: userForm.cpf,
                email: userForm.email,
                mobilePhone: userForm.mobilePhone,
                address: address.street,
                addressNumber: userForm.addressNumber,
                complement: userForm.complement,
                province: address.neighborhood,
                postalCode: address.cep,
                notificationDisabled: true,
                observations: (userForm.shirt ? `camiseta:${userForm.shirt}` : "")
              },
              payment: {
                billingType: 'CREDIT_CARD',
                value: price,
                nextDueDate: Moment().format("YYYY-MM-DD"),
                cycle: 'MONTHLY',
                description: selectedPlan.asaasDescription,
                creditCard: {
                  holderName: userForm.cardName,
                  number: userForm.creditCard.number.replaceAll(' ', ''),
                  expiryMonth: expires[0],
                  expiryYear: `20${expires[1]}`,
                  ccv: userForm.cvc,
                },
                creditCardHolderInfo,
                remoteIp: ip,
              }
            })

            if (resp.success) {
              setIsLoading();
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              setShowSuccess(true);
            } else {
              setIsLoading();
              setShowError(true);
              setTimeout(() => {
                setShowError();
              }, 5000)
            }


          }}>Confirmar minha contribuição...</button>
        </div>
      </div>
      <LoadingWrapper className={`${!isloading && 'd-none'}`}>
        <LoadingBox>
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#47CD89', '#47CD89', '#47CD89', '#47CD89', '#47CD89']}
          />
          <p>Processando sua associação...</p>
        </LoadingBox>
      </LoadingWrapper>
      <SuccessWrapper className={`${!showSuccess && 'd-none'}`}>
        <SuccessBox>
          <h2>Obrigado por se juntar ao Projeto BEM!</h2>
          <p>Com imensa gratidão, estendemos nossos sinceros agradecimentos por se unir ao Projeto BEM, fortalecendo nossa missão de transformação através da música. Sua associação ressoa como uma sinfonia de generosidade nos corações das crianças, adolescentes e adultos que atendemos.</p>
          <iframe className="videoFrame" src="https://www.youtube.com/embed/b3bOsCW6Tfo?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <p>Em reconhecimento a essa dedicação, temos a honra de compartilhar a música tema do Projeto BEM, composta por <b>Rodrigo dos Reis</b> e enriquecida pelas colaborações especiais de <b>Toninho Geraes</b>, <b>João Cristal</b>, <b>Karina Salles</b> e <b>Thiago de Xangô</b>. Cada acorde dessa melodia é um lembrete do seu papel vital na construção de um futuro mais harmonioso e esperançoso. Seja bem-vindo(a) a essa jornada musical de impacto e gratidão.</p>
        </SuccessBox>
      </SuccessWrapper>
    </div>
  );
}

export default Checkout;
