import React, {useState, useEffect} from 'react';
import styled from 'styled-components'
import InputMask from 'react-input-mask';
import { getBrand } from 'credit-card-get-brand';

const Wrapper = styled.div`
  position: relative;

  .form-control {
    padding-left: 50px;
  }
`;

const Icon = styled.img`
  position: absolute;
  top: 8px;
  left: 5px;
  padding: 2px 4px;
  // border: 1px solid #000;
  border: 1px solid #F2F4F7;
  border-radius: 5px;
  max-width: 40px;
  max-height: 28px;
`;

const clearNumber = (card = '') => {
  let cleared = card.replaceAll('*', '')
  cleared = cleared.replaceAll(' ', '')

  return cleared;
}

function CreditCard({ onUpdate }) {
  const [cardInfo, setCardInfo] = useState();

  useEffect(() => {
    onUpdate(cardInfo);
  }, [cardInfo]);

  return (
    <Wrapper>
      {cardInfo && <Icon src={cardInfo.image} />}
      <InputMask mask="9999 9999 9999 9999" placeholder="9999 9999 9999 9999" className="form-control" onChange={async (e) => {
        const brand = getBrand(e.target.value);

        if (brand) {
          setCardInfo({
            ...brand,
            number: e.target.value,
          })
        } else {
          const cardNumber = clearNumber(e.target.value);

          if (cardNumber.length === 16) {
            console.log(`set true`)
            setCardInfo({
              code: '',
              name: '',
              image: '',
              number: e.target.value,
            })
          } else {
            setCardInfo()
          }
        }
      }} pattern="[0-9]*" maskChar="*" />
    </Wrapper>
  );
}

export default CreditCard;
